import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import logo from "./../images/logo-footer.svg"

const Footer = ({ siteTitle }) => (
  <footer>
      <p className="footer-copyright">&copy; {siteTitle} {new Date().getFullYear()}</p>
      <Link to="/imprint/">Impressum</Link>
      <ul className="footer-social">
            <li>
                <a
                    href="https://www.facebook.com/gaertnereikremp/"
                    className="footer-social__link icon-facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span>Kremp und Blumen bei Facebook</span>
                </a>
            </li>
            <li>
                <a
                    href="https://www.instagram.com/gaertnereikremp/"
                    className="footer-social__link icon-instagram"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span>Kremp und Blumen bei Instagram</span>
                </a>
            </li>
      </ul>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
